<template>
    <div>
        <vue-title title="Договор | KEL.KZ"></vue-title>
        <Header :routes="headerRoutes.defaultsoutes"/>
        <div class="container py-4">
            <div class="long-title text-center mb-3">
                <h1 class="page-title">Договор {{contract ? contract.contractCaption : ''}}</h1>
            </div>
            <div class="row">
                <div class="col-12">
                    <ContractDetail :contractId="contractId" :Agent="Agent" :getContract="getContract" />
                </div>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
    import headerRoutes from './header-routes';
    import {mapGetters} from 'vuex';
    import {Header, Footer} from '../../shared/components';

    import ContractDetail from './contracts/ContractDetail';

    export default {
        name: 'ProfileContract',
        components: {
            Header,
            Footer,
            ContractDetail

        },
        data() {
            return {
                headerRoutes,
                contractId: this.$route.params.contractId,
                contract: null,
            }
        },
        mounted() {

        },
        methods: {
            getContract(contract) {
                this.contract = contract;
            },
        },
        computed: {
            ...mapGetters([
                'isLoading',
                'Agent'
            ])
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    .tooltip-lable {
        display: block;
        position: absolute;
        padding-top: 5px;
        margin-left: 20px;
    }
</style>
